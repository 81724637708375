import { render, staticRenderFns } from "./NewInfo.vue?vue&type=template&id=a63225bc&scoped=true&"
import script from "./NewInfo.vue?vue&type=script&lang=js&"
export * from "./NewInfo.vue?vue&type=script&lang=js&"
import style0 from "./NewInfo.vue?vue&type=style&index=0&id=a63225bc&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a63225bc",
  null
  
)

export default component.exports