<template>
  <div class="project-container">
    <div class="project-header">
      <h3 class="title">项目信息</h3>
    </div>
    <div class="project-body">
      <van-cell-group v-if="loaded === 1">
        <template v-for="({label, value}, key) in record">
          <van-cell :title="label" :value="value" :key="key"/>
        </template>
      </van-cell-group>
      <div v-else-if="loaded === 2" class="loading">
        <van-loading vertical>项目信息获取中...</van-loading>
      </div>
      <van-empty v-else image="error" description="获取失败或参数错误"/>
    </div>
  </div>
</template>

<script>
import {Cell, CellGroup, Loading, Empty} from 'vant';
import {getZXProjectInfo} from "@/api/api.js";

export default {
  name: 'ProjectInfo',
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Loading.name]: Loading,
    [Empty.name]: Empty
  },
  data() {
    return {
      record: {},
      loaded: 0
    }
  },
  watch: {
    '$route': {
      handler({query}) {
        if (query.projectId && query.pdfSealApplyId) {
          this.getData(query)
        }
      },
      immediate: true
    }
  },
  mounted(){
    document.title = "智勋设计"
  },
  methods: {
    getData({projectId, pdfSealApplyId}) {
      this.loaded = 2
      getZXProjectInfo({projectId, pdfSealApplyId}).then(res => {
        const data = res.result
        this.setValue('code', {key: 1, label: '项目编号', value: data.recordNo || '-'})
        this.setValue('area', {
          key: 2,
          label: '省/市/区',
          value: [data.province, data.city, data.address].filter(r => r).join('-') || '-'
        })
        this.setValue('customer', {key: 3, label: '客户名称', value: data.customerInfoSubName || '-'})
        this.setValue('name', {key: 4, label: '项目名称', value: data.name || '-'})
        this.setValue('stamp', {key: 5, label: '出图章', value: data.stampOutName || '-'})
        this.setValue('registration', {key: 6, label: '注册章', value: data.registrationSealName || '-'})
        this.setValue('type', {key: 7, label: '盖章类型', value: ['成册', '单册'][data.uploadType] || '-'})
        this.setValue('major', {key: 8, label: '专业', value: data.major || '-'})
        this.setValue('unit', {key: 9, label: '责任单位', value: data.applyDeptName || '-'})

        this.loaded = 1
      }).catch(() => {
        this.loaded = 0
      })
    },
    setValue(name, {key, ...value}) {
      this.$set(this.record, name, value)
    }
  }
}
</script>

<style scoped lang="less">
.project {
  &-header {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: #002b45;
    position: sticky;
    top: 0;
    z-index: 7;

    .title {
      flex: auto;
      margin: 0;
      text-align: center;
      font-size: 20px;
      color: #fff;
    }

    &::before,
    &::after {
      content: '';
      display: block;
      width: 70px;
      height: 100%;
      flex: none;
    }

    &::after {
      background: url('../../../assets/zxlogo.png') no-repeat center left;
      background-size: 60px 30px;
    }
  }

  &-body {
    .loading {
      padding-top: 70px;
    }

    &::v-deep {
      .van-cell__value {
        flex: 2;
        text-align: left;
      }
    }
  }
}
</style>
